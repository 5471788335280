/* Fonts */
@font-face {
  font-family: Regular;
  src: url(../public/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: p-bold;
  src: url(../public/fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: p-light;
  src: url(../public/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: p-medium;
  src: url(../public/fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: p-extralight;
  src: url(../public/fonts/Poppins-ExtraLight.ttf);
}
:root {
  --primary-white: #f6f6f6;
  --secondary-white: #bdbdbd;
  --primary-gray: #a7a7a7;
  --primary-black: #111111;
 /*  --primary-color: #ff731d;*/
  --primary-color: #ff0000;

  --white-bg: rgb(32, 32, 32);
  --black-bg: rgba(0, 0, 0, 0.83);
  /* --primary-color:#3191ff; */
  /* --primary-color:#407c87; */
}
* {
  scroll-behavior: smooth;
}
.App {
  background: url(../public/images/pattern8-pattern-63b.png);
}
.logo{
  width: 40px;
  height: 40px;
}
/* slider css */
.client-slider-container {
  position: relative;
  height: fit-content;
  font-family: p-light;
  color: var(--secondary-white);
}

.client-slider-content {
  height: 160px;
  background-color: var(--white-bg);
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
}
.client-author{
  font-family: p-bold;
  font-size: 13px;
  color: var(--primary-white);
}
.client-role{
  font-size: 11px;

}

.controls {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: space-between;
  justify-self: center;
  

  
}

.arrow-buttons {
  display: flex;
  gap: 10px;
}

.arrow-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.dot-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
  width: 100%;
  bottom: 20px;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--secondary-white);
  cursor: pointer;
}

.dot.active {
  background-color: var(--primary-color);
}





.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--primary-black);
}
.offcanvas-title {
  color: var(--primary-color);
  font-size: 12px;
  font-family: Regular;
}
#offcanvasNavbar {
  width: 300px;
  height: 100%;
  background-color: var(--primary-black);
}
#logo {
  display: flex;
  font-family: p-medium;
  justify-content: end;
  width: fit-content;
}
#logo::after {
  content: "";
  background-color: var(--primary-color);
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 5px;
  align-self: start;
  justify-self: flex-end;
  margin-top: 2px;
}
.offcanvas-footer {
  font-size: 12px;
  font-family: p-light;
  margin-top: 25px;
  color: var(--primary-gray);
}
.nav {
  background-color: var(--primary-black);
  color: var(--primary-white);
  font-family: p-medium;
}
.no {
  color: var(--primary-white);
}

#b {
  border: none;
}
@media (max-width: 374px) {
  .title {
    font-size: 25px;
    font-family: p-bold;
    color: var(--primary-white);
    letter-spacing: 0.1rem;
  }
  .brian {
    font-size: 25px;
  }
  .subtitle {
    color: var(--primary-gray);
    font-size: 14px;
    font-family: Regular;
    letter-spacing: 0.1rem;
  }
}
@media (max-width: 500px) {
  .contacts-header {
    font-family: p-medium;
    font-size: 23px;
    color: #e6e6e6f2;
  }
  .header-text {
    font-size: 16px;
  }
}
@media (min-width: 501px) {
  .contacts-header {
    font-family: p-medium;
    font-size: 26px;
    color: #e6e6e6f2;
  }
  .header-text {
    font-size: 17px;
  }
}
@media (min-width: 375px) {
  .title {
    font-size: 34px;
    font-family: p-bold;
    color: var(--primary-white);
    letter-spacing: 0.1rem;
  }
  .brian {
    font-size: 34px;
  }
  .subtitle {
    color: var(--primary-gray);
    font-size: 16px;
    font-family: Regular;
    letter-spacing: 0.1rem;
  }
}
@media (max-width: 575px) {
  .lets-chat a,
  .know-more a {
    font-size: 10px;
  }
}
@media (min-width: 576px) {
  .lets-chat a,
  .know-more a {
    font-size: 10px;
  }
}

@media (min-width: 701px) {
  .contacts-header {
    font-family: p-medium;
    font-size: 29px;
    color: #e6e6e6f2;
  }
  .header-text {
    font-size: 18px;
  }
}
@media (max-width: 450px) {
  .moral {
    width: 100%;
  }
}
@media (min-width: 450px) {
  .moral {
    width: 90%;
  }
}

@media (min-width: 550px) {
  .moral {
    width: 80%;
  }
}
@media (min-width: 625px) {
  .moral {
    width: 300px;
  }
}
@media (min-width: 665px) {
  .moral {
    width: 320px;
  }
}
@media (min-width: 710px) {
  .moral {
    width: 340px;
  }
}
@media (max-width: 768px) {
  .landingPage {
    background: url(../public/images/jj-sm.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media (min-width: 768px) {
  .landingPage {
    background: url(../public/images/jj.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .title {
    font-family: p-bold;
    color: var(--primary-white);
    letter-spacing: 0.1rem;
    font-size: 45px;
  }
  .brian {
    font-size: 45px;
  }
  .subtitle {
    color: var(--primary-gray);
    font-size: 20px;
    font-family: Regular;
    letter-spacing: 0.1rem;
  }
  .moral {
    width: 49%;
  }
}
@media (min-width: 870px) {
  .title {
    font-family: p-bold;
    color: var(--primary-white);
    letter-spacing: 0.1rem;
    font-size: 50px;
  }
  .brian {
    font-size: 50px;
  }
  .subtitle {
    color: var(--primary-gray);
    font-size: 24.5px;
    font-family: Regular;
    letter-spacing: 0.1rem;
  }
  .lets-chat a,
  .know-more a {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .service-card {
    width: 300px;
    background-color: var(--black-bg);
    color: var(--primary-white);
    border-radius: 15px;
    height: 200px;
  }
  .card-title {
    color: var(--primary-white);
    font-family: Regular;
    font-weight: 400;
    font-size: 18px;
  }
  .card-description {
    color: var(--primary-gray);
    font-family: p-extralight;
  }
  .project-card {
    width: 300px;
  }
  .img-caption {
    font-size: 16px;
  }
  .break::before {
    content: "";
    width: 90%;
    height: 1px;
    background-color: var(--primary-color);
    position: relative;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .service-card {
    width: 350px;
    background-color: var(--black-bg);
    color: var(--primary-white);
    border-radius: 15px;
    height: 200px;
  }
  .card-title {
    color: var(--primary-white);
    font-family: Regular;
    font-weight: 400;
    font-size: 18px;
  }
  .card-description {
    color: var(--primary-gray);
    font-family: p-extralight;
  }
  .project-card {
    width: 350px;
  }
  .img-caption {
    font-size: 18px;
  }
  .break::before {
    content: "";
    width: 95%;
    height: 1px;
    background-color: var(--primary-color);
    position: relative;
    margin-bottom: 20px;
  }
  .paragraph {
    font-size: 17px;
  }
  .heading {
    font-size: 24px;
  }
  .header-text {
    font-size: 18px;
  }
  .image_md {
    width: 350px;
  }
  .title {
    font-family: p-bold;
    color: var(--primary-white);
    letter-spacing: 0.1rem;
    font-size: 55px;
  }
  .brian {
    font-size: 55px;
  }
  .subtitle {
    color: var(--primary-gray);
    font-size: 25px;
    font-family: Regular;
    letter-spacing: 0.1rem;
  }
}
@media (min-width: 1009px) {
  .contacts-header {
    font-family: p-medium;
    font-size: 36px;
    color: #e6e6e6f2;
  }
}

.landing-bo {
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.80);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.landing-box {
  height: 100%;
}
.landingPage.visible {
  opacity: 1;
  visibility: visible;
}

.landingPage.hidden {
  opacity: 0;
  visibility: hidden;
}

.brian {
  color: var(--primary-color);
}
.know-more {
  background-color: white;
  border: none;
  border-radius: 0;
  font-family: Regular;
}

.lets-chat {
  background: var(--primary-color);
  border: none;
  border-radius: 0;
  font-family: Regular;
}
.lets-chat a,
.know-more a {
  color: var(--primary-black);
  letter-spacing: 0.15rem;
}
.highlight {
  font-weight: 600;
  color: var(--primary-white);
}
.about {
  background-color: var(--black-bg);
  color: var(--primary-gray);
  font-family: p-extralight;
  letter-spacing: 0.03rem;
}
.call {
  color: var(--primary-gray);
}
.header-text {
  color: var(--primary-color);
  font-family: p-medium;
  letter-spacing: 0.2rem;
}
#header-text {
  color: var(--primary-color);
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-family: Regular;
  font-size: 10px;
}
#clients-header-text {
  color: var(--primary-color);
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-family: Regular;
  font-size: 14px;
}

.moral-label {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: var(--primary-color);
  font-weight: 700;
  font-family: p-medium;
}
.moral-title {
  height: 40px;
  font-weight: 700;
  font-family: p-medium;
  color: var(--primary-white);
  padding: 0;
  margin: 0;
}
.moral-description {
  font-family: p-extralight;
  color: var(--primary-gray);
}
.services {
  background-color: var(--white-bg);
  letter-spacing: 0.03rem;
}

.projects {
  background-color: var(--black-bg);
  letter-spacing: 0.03rem;
}
.portfolio-sub {
  color: var(--primary-white);
  font-family: p-medium;
}
.filterable-button {
  font-family: p-light;
  color: var(--primary-white);
}
.bt {
  color: var(--primary-color);
}
.img-place {
  width: 300px;
}
.img-captio {
  background-color: var(--white-bg);
}
.img-caption {
  font-family: p-medium;
  color: var(--primary-white);
}
.load a {
  font-family: p-light;
}
.custom-outline-button {
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 0 10px;
  cursor: pointer;
  border-radius: 20px;

}

.custom-outline-button:hover {
  background-color: var(--primary-color);
  color: white;
}
.custom-outline-button.selected {
  background-color: var(--primary-color);
  color: white;
}
.contacts {
  background-color: rgb(22, 22, 22);
  color: var(--primary-gray);
  font-family: p-light;
  font-size: 14px;
}

.contacts-footer {
  background-color: var(--primary-black);
  font-family: Regular;
  font-size: 12px;
  color: var(--primary-white);
  text-align: center;
}

#contacts-header-text {
  color: var(--primary-color);
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-family: Regular;
  font-size: 12px;
}
.social {
  font-size: 16px;
}
